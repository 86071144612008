import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Category } from "../../interfaces/Category";
import { RootState } from "../../app/store";
import { updateUser } from "../auth/authSlice";

export const definitiveCategories = [
    //A2
    { value: "A2-ENFERMERIA-DEFINITIVA", label: "A2 - Enfermera/o" },
    { value: "A2-FISIOTERAPIA-DEFINITIVA", label: "A2 - Fisioterapeuta" },
    { value: "A2-LOGOPEDA-DEFINITIVA", label: "A2 - Logopeda" },
    { value: "A2-MATRONA-DEFINITIVA", label: "A2 - Matrón/a" },
    { value: "A2-TERAPEUTA-OCUPACIONAL-DEFINITIVA", label: "A2 - Terapeuta Ocupacional" },
    { value: "A2-ARQUITECTO-TECNICO-DEFINITIVA", label: "A2 - Arquitecta/o Técnica/o" },
    { value: "A2-RRLL-DEFINITIVA", label: "A2 - Diplomada/o en Relaciones Laborales" },
    { value: "A2-GGFA-DEFINITIVA", label: "A2 - Grupo de Gestión de la Función Administrativa" },
    { value: "A2-INGENIERO-TECNICO-INDUSTRIAL-DEFINITIVA", label: "A2 - Ingeniera/o Técnica/o Industrial" },
    { value: "A2-INGENIERO-TECNICO-DEFINITIVA", label: "A2 - Ingeniera/o Técnica/o" },
    { value: "A2-TECNICA-O-TITULADA-O-MEDIO-DEFINITIVA", label: "A2 - Técnica/o Titulada/o Medio" },
    { value: "A2-TTM-INFORMATICA-DEFINITIVA", label: "A2 - Técnica/o Titulado Medio de Informática" },
    { value: "A2-TRABAJADOR-SOCIAL-DEFINITIVA", label: "A2 - Trabajadora/o Social" },

    //A1
    { value: "A1-MEDICO-ADMISION-Y-DOCUMENTACION-DEFINITIVA", label: "A1 - Médica/o de Admisión y Documetación Clínica" },
    { value: "A1-MEDICA-O-DE-FAMILIA-DEFINITIVA", label: "A1 - Medica/o de Familia" },
    { value: "A1-MEDICA-O-DE-URGENCIA-HOSPITALARIA-DEFINITIVA", label: "A1 - Medica/o de Urgencia Hospitalaria" },
    { value: "A1-ODONTOESTOMATOLOGA-O-DEFINITIVA", label: "A1 - Odontoestomatóloga/o" },
    { value: "A1-PEDIATRA-DE-AP-DEFINITIVA", label: "A1 - Pediatra de Equipo de Atención Primaria" },
    { value: "A1-TECNICO-SALUD-PUBLICA-DEFINITIVA", label: "A1 - Técnica/o de Salud Pública" },
    { value: "A1-GRUPO-TECNICO-DE-LA-FUNCION-ADMINISTRATIVA-DEFINITIVA", label: "A1 - Grupo Técnico de la Función Administrativa" },
    { value: "A1-INGENIERO-SUPERIOR-DEFINITIVA", label: "A1 - Ingeniera/o Superior" },
    { value: "A1-TECNICO-TITULADO-SUPERIOR_ESTATUTARIO-DEFINITIVA", label: "A1 - Técnica/o Titulada/o Superior" },
    { value: "A1-TECNICO-TITULADO-SUPERIOR-ADE-DEFINITIVA", label: "A1 - Técnica/o Titulada/o Superior - ADE" },
    { value: "A1-BIOLOGO_ESTATUTARIO-DEFINITIVA", label: "A1 - Técnica/o Titulada/o Superior Bióloga/o" },
    { value: "A1-TECNICO-TITULADO-SUPERIOR-ECONOMICO-DEFINITIVA", label: "A1 - Técnica/o Titulada/o Superior Económico" },
    { value: "A1-TECNICO-TITULADO-SUPERIOR-INFORMATICA-DEFINITIVA", label: "A1 - Técnica/o Titulada/o Superior Informática" },
    { value: "A1-TECNICO-TITULADO-SUPERIOR-JURIDICO-DEFINITIVA", label: "A1 - Técnica/o Titulada/o Superior Jurídico" },
    { value: "A1-TTS-PSICOLOGIA-DEFINITIVA", label: "A1 - Técnica/o Titulada/o Superior en Psicología" },

    //FEA
    { value: "FEA-ALERGOLOGIA-DEFINITIVA", label: "FEA - Alergología" },
    { value: "FEA-ANASISI-CLINICOS-DEFINITIVA", label: "FEA - Análisis Clínico" },
    { value: "FEA-ANATOMIA-PATOLOGICA-DEFINITIVA", label: "FEA - Anatomía Patológica" },
    { value: "FEA-ANESTESIOLOGIA-Y-REANIMACION-DEFINITIVA", label: "FEA - Anestesiología y Reanimación" },
    { value: "FEA-ANGIOLOGIA-Y-CIRUGIA-VASCULAR-DEFINITIVA", label: "FEA - Angiología y Cirugía Vascular" },
    { value: "FEA-APARATO-DIGESTIVO-DEFINITIVA", label: "FEA - Aparato Digestivo" },
    { value: "FEA-BIOQUIMICA-CLINICA-DEFINITIVA", label: "FEA - Bioquímica Clínica" },
    { value: "FEA-CARDIOLOGIA-DEFINITIVA", label: "FEA - Cardiología" },
    { value: "FEA-CIRUGIA-CARDIOVASCULAR-DEFINITIVA", label: "FEA - Cirugía Cardiovascular" },
    { value: "FEA-CIRUGIA-GENERAL-Y-APARATO-DIGESTIVO-DEFINITIVA", label: "FEA - Cirugía General y Aparato Digestivo" },
    { value: "FEA-CIRUGIA-ORAL-Y-MAXILOFACIAL-DEFINITIVA", label: "FEA - Cirugía Oral y Maxilofacial" },
    { value: "FEA-CIRUGIA-ORTOPEDICA-Y-TRAUMATOLOGICA-DEFINITIVA", label: "FEA - Cirugía Ortopédica y Traumatología" },
    { value: "FEA-CIRUGIA-PEDIATRICA-DEFINITIVA", label: "FEA - Cirugía Pediátrica" },
    { value: "FEA-CIRUGIA-PLASTICA-ESTETICA-Y-REPARADORA-DEFINITIVA", label: "FEA - Cirugía Plástica, Estética y Reparadora" },
    { value: "FEA-CIRUGIA-TORACICA-DEFINITIVA", label: "FEA - Cirugía Torácica" },
    { value: "FEA-DERMATOLOGIA-MEDICO-QUIRURGICA-Y-VENEREOLOGIA-DEFINITIVA", label: "FEA - Dermatología Médico-Quirúrgica y Venereología" },
    { value: "FEA-ENDOCRINOLOGIA-Y-NUTRICION-DEFINITIVA", label: "FEA - Endocrinología y Nutrición" },
    { value: "FEA-FARMACIA-HOSPITALARIA-DEFINITIVA", label: "FEA - Farmacia Hospitalaria" },
    { value: "FEA-FARMACOLOGIA-CLINICA-DEFINITIVA", label: "FEA - Farmacología Clínica" },
    { value: "FEA-GERIATRIA-DEFINITIVA", label: "FEA - Geriatría" },
    { value: "FEA-HEMATOLOGIA-Y-HEMOTERAPIA-DEFINITIVA", label: "FEA - Hematología y Hemoterapia" },
    { value: "FEA-MEDICINA-DEL-TRABAJO-DEFINITIVA", label: "FEA - Medicina del Trabajo" },
    { value: "FEA-MEDICINA-FAMILIAR-Y-COMUNITARIA-DEFINITIVA", label: "FEA - Medicina Familiar y Comunitaria" },
    { value: "FEA-MEDICINA-FISICA-Y-REHABILITACION-DEFINITIVA", label: "FEA - Medicina Física y Rehabilitación" },
    { value: "FEA-MEDICINA-INTENSIVA-DEFINITIVA", label: "FEA - Medicina Intensiva" },
    { value: "FEA-MEDICINA-INTERNA-DEFINITIVA", label: "FEA - Medicina Interna" },
    { value: "FEA-MEDICINA-NUCLEAR-DEFINITIVA", label: "FEA - Medicina Nuclear" },
    { value: "FEA-MEDICINA-PREVENTIVA-Y-SALUD-PUBLICA-DEFINITIVA", label: "FEA - Medicina Preventiva y Salud Pública" },
    { value: "FEA-MICROBIOLOGIA-Y-PARASITOLOGIA-DEFINITIVA", label: "FEA - Microbiología y Parasitología" },
    { value: "FEA-NEFROLOGIA-DEFINITIVA", label: "FEA - Nefrología" },
    { value: "FEA-NEUMOLOGIA-DEFINITIVA", label: "FEA - Neumología" },
    { value: "FEA-NEUROCIRUGIA-DEFINITIVA", label: "FEA - Neurocirugía" },
    { value: "FEA-NEUROFISIOLOGIA-DEFINITIVA", label: "FEA - Neurofisiología Clínica" },
    { value: "FEA-NEUROLOGIA-DEFINITIVA", label: "FEA - Neurología" },
    { value: "FEA-OFTALMOLOGIA-DEFINITIVA", label: "FEA - Oftalmología" },
    { value: "FEA-OBSTETRICIA-Y-GINECOLOGIA-DEFINITIVA", label: "FEA - Obstetricia y Ginecología" },
    { value: "FEA-ONCOLOGIA-MEDICA-DEFINITIVA", label: "FEA - Oncología Médica" },
    { value: "FEA-ONCOLOGIA-RADIOTERAPICA-DEFINITIVA", label: "FEA - Oncología Radioterápica" },
    { value: "FEA-OTORRINOLARINGOLOGIA-DEFINITIVA", label: "FEA - Otorrinolaringología" },
    { value: "FEA-PEDIATRIA-Y-SUS-AREAS-ESPECIFICAS-DEFINITIVA", label: "FEA - Pediatría y sus Áreas Específicas" },
    { value: "FEA-PSIQUIATRIA-DEFINITIVA", label: "FEA - Psiquiatría" },
    { value: "FEA-PSICOLOGIA-CLINICA-DEFINITIVA", label: "FEA - Psicología Clínica" },
    { value: "FEA-RADIODIAGNOSTICO-DEFINITIVA", label: "FEA - Radiodiagnóstico" },
    { value: "FEA-RADIOFISICA-HOSPITALARIA-DEFINITIVA", label: "FEA - Radiofísica Hospitalaria" },
    { value: "FEA-REUMATOLOGIA-DEFINITIVA", label: "FEA - Reumatología" },
    { value: "FEA-UROLOGIA-DEFINITIVA", label: "FEA - Urología" },

    //C1
    { value: "C1-TE-ANATOMIA-PATOLOGICA-DEFINITIVA", label: "C1 - Técnica/o Especialista en Anatomía Patológica" },
    { value: "C1-TE-DOCUMENTACION-SANITARIA-DEFINITIVA", label: "C1 - Técnica/o Especialista en Documentación Sanitaria" },
    { value: "C1-TE-HIGIENE-BUCODENTAL-DEFINITIVA", label: "C1 - Técnica/o Especialista en Higuiene Bucodental" },
    { value: "C1-TE-LABORATORIO-DEFINITIVA", label: "C1 - Técnica/o Especialista en Laboratorio" },
    { value: "C1-TE-MEDICINA-NUCLEAR-DEFINITIVA", label: "C1 - Técnica/o Especialista en Medicina Nuclear" },
    { value: "C1-TE-RADIODIAGNOSTICO-DEFINITIVA", label: "C1 - Técnica/o Especialista en Radiodiagnóstico" },
    { value: "C1-TE-RADIOTERAPIA-DEFINITIVA", label: "C1 - Técnica/o Especialista en Radioterapia" },
    { value: "C1-COCINERO-DEFINITIVA", label: "C1 - Cocinera/o" },
    { value: "C1-TECNICO-SUPERIOR-EN-GESTION-Y-SERVICIOS-DEFINITIVA", label: "C1 - Técnica/o Superior Gestión y Servicios" },
    { value: "C1-GRUPO-ADMINISTRATIVO-DE-LA-FUNCION-ADMINISTRATIVA-DEFINITIVA", label: "C1 - Grupo Administrativo de la Función Administrativa" },

    //C2
    { value: "C2-AUXILIAR-DE-ENFERMERIA-DEFINITIVA", label: "C2 - Auxiliar de Enfermería" },
    { value: "C2-ALBANIL-DEFINITIVA", label: "C2 - Albañil" },
    { value: "C2-CALEFACTOR-DEFINITIVA", label: "C2 - Calefactor/a" },
    { value: "C2-CARPINTERO-DEFINITIVA", label: "C2 - Carpintera/o" },
    { value: "C2-CONDUCTOR-DEFINITIVA", label: "C2 - Conductor/a" },
    { value: "C2-CONDUCTOR-DE-INSTALACIONES-DEFINITIVA", label: "C2 - Conductor/a de instalaciones" },
    { value: "C2-ELECTRICISTA-DEFINITIVA", label: "C2 - Electricista" },
    { value: "C2-FONTANERO-DEFINITIVA", label: "C2 - Fontanera/o" },
    { value: "C2-GAAFA-DEFINITIVA", label: "C2 - Grupo Auxiliar Administrativo de la Función Administrativa" },
    { value: "C2-MECANICO-DEFINITIVA", label: "C2 - Mecánica/o" },
    { value: "C2-PINTOR-DEFINITIVA", label: "C2 - Pintor/a" },
    { value: "C2-TELEFONISTA-DEFINITIVA", label: "C2 - Telefonista" },
    { value: "C2-GOBERNANTA-DEFINITIVA", label: "C2 - Gobernanta" },

    //OTRO
    { value: "OTRO-AYUDANTE-DE-COCINA_LABORAL-DEFINITIVA", label: "OTRO - Ayudante de Cocina" },
    { value: "OTRO-CELADOR-DEFINITIVA", label: "OTRO - Celador" },
    { value: "OTRO-LAVANDERA-DEFINITIVA", label: "OTRO - Lavandera" },
    { value: "OTRO-LIMPIADOR-DEFINITIVA", label: "OTRO - Limpiador/a" },
    { value: "OTRO-PEON-DEFINITIVA", label: "OTRO - Peón" },
    { value: "OTRO-PLANCHADORA-DEFINITIVA", label: "OTRO - Planchador/a" },
    { value: "OTRO-PINCHE-DEFINITIVA", label: "OTRO - Pinche" },

    //Personal laboral
    { value: "PL-FEA-CARDIOLOGIA-DEFINITIVA", label: "PL-A1 - Adjunto Cardiología" },
    { value: "PL-A1-ADJUNTO-GERIATRIA-LABORAL-DEFINITIVA", label: "PL-A1 - Adjunto Geriatría" },
    { value: "PL-A1-TECNICO-TITULADO-SUPERIOR_LABORAL-DEFINITIVA", label: "PL-A1 - Técnica/o Titulada/o Superior" },
    { value: "A1-PL-BIOLOGO_LABORAL-DEFINITIVA", label: "PL-A1 - Técnica/o Titulada/o Superior Bióloga/o" },
    { value: "PL-A2-ENFERMERIA-DEFINITIVA", label: "PL-A2 - Enfermera/o" },
    { value: "PL-C2-AUXILIAR-DE-ENFERMERIA-DEFINITIVA", label: "PL-C2 - Auxiliar de Enfermería" },
    { value: "PL-C2-AUXILIAR-DE-ALMACEN-DEFINITIVA", label: "PL-C2 - Auxiliar de Almacén" },
    { value: "PL-C2-GAAFA-DEFINITIVA", label: "PL-C2 - Grupo Auxiliar Administrativo de la Función Administrativa" },
    { value: "PL-OTRO-COSTURERA-LAVANDERA-PLANCHADORA-DEFINITIVA", label: "PL-OTRO - Costurera, lavandera, planchadora" },
    { value: "PL-OTRO-LENCERA-LABORAL-DEFINITIVA", label: "PL-OTRO - Lencera/o" },
    { value: "PL-OTRO-LIMPIADOR-DEFINITIVA", label: "PL-OTRO - Limpiador/a" },
    
]

export const categories: { value: string, label: string }[] = [
    //FEA
    
    //A1

    //A2

    //C2

    //OTRO

    //PL
];

export const getCategory = (value: string) => [...definitiveCategories, ...categories].find(e => e.value === value);

interface CategoryState { defaultCategory?: Category };
export const initialState: CategoryState = { defaultCategory: undefined };

export const categorySlice = createSlice({
    name: 'category',
    initialState,
    reducers: {
        setUserCategory: (state, action: PayloadAction<string>) => {
            const category = [...categories, ...definitiveCategories].find(e => e.value === action.payload);
            if (category) state.defaultCategory = category;
        }
    },
    extraReducers: builder => {
        builder
            .addCase(setDefaultCategoryAndUpdateUser.fulfilled, (state, action) => {
                state.defaultCategory = action.payload
            });
    },
});

export const { setUserCategory } = categorySlice.actions;

export default categorySlice.reducer;

export const getCategoryLabel = (value: string | undefined) => {
    if (!value) return undefined;
    return [...categories, ...definitiveCategories].find(e => e.value === value)?.label;
}

export const setDefaultCategoryAndUpdateUser = createAsyncThunk<Category, Category, { state: RootState }>('categories/setDefaultCategoryAndUpdateUser', async (category, { getState, dispatch }) => {
    const user = getState().auth.user;
    if (user) dispatch(updateUser({ ...user, defaultCategory: category.value }));
    return category;
});