import React from "react"
import ReactDOM from "react-dom/client"
import { Provider } from "react-redux"
import { store } from "./app/store"
import App from "./App"

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import "./styles/fonts/ethnocentric/ethnocentric.css";

import { BrowserRouter } from "react-router-dom";

if (["scs-preguntas.web.app", "scs-preguntas.firebaseapp.com"].some(e => e === location.host)) {
  window.location.replace("https://scspreguntas.com/");
}

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>
  </React.StrictMode>,
)
